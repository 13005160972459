.bg-transparent {
  background-color: transparent !important;
}

.bg-orange {
  background-color: #fc7300 !important;
}

.bg-teal-500 {
  background-color: #076461 !important;
}
.bg-teal-600 {
  background-color: #06514f !important;
}

.bg-gray-100 {
  background-color: #f1f5f9 !important;
}

.bg-gray-200 {
  background-color: #dde4e3 !important;
}
.bg-gray-500 {
  background-color: #667085 !important;
}

.bg-surface {
  background-color: #f7f8f8 !important;
}

.bg-offwhite {
  background-color: #fffcf1 !important;
}

.text-offwhite {
  color: #ffd681 !important;
}

.text-orange {
  color: #fc7300 !important;
}

.teal-500 {
  color: #076461 !important;
}

.teal-600 {
  color: #06514f !important;
}
.gray-200 {
  color: #dde4e3 !important;
}
.gray-500 {
  color: #64748b !important;
}
.gray-700 {
  color: #334155 !important;
}
.gray-900 {
  color: #202827 !important;
}

.bg-f2ecfd {
  background-color: #f2ecfd !important;
}
.bg-ecfcfd {
  background-color: #ecfcfd !important;
}
