.mb20-16 {
  margin-bottom: 20px;
}

.event-content {
  margin: 0;
  padding: 122px 2% 32px;
}

.event-card {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
}

.event-card-min {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  padding: 16px;
}

.event-card-overflow {
  overflow-y: auto !important;
  height: calc(100vh - 202px);
  position: relative;
}

.event-list {
  overflow-y: auto !important;
  height: calc(100vh - 330px);
}

.event-list-card {
  border-radius: 8px;
  background: #eef1f1;
  margin-bottom: 16px;
}
.event-list-card-title {
  color: var(--text-label, #043e3c);
  font-size: 16px;
  font-weight: 500;
}

.event-list-card-time {
  color: #809997;
  font-size: 13px;
  font-weight: 400;
}

.event-list-card-branch {
  padding: var(--radius-m, 8px) var(--radius-l, 12px);
  align-items: center;
  border-radius: 999px;
  background: #043e3c;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 0;
  color: #f7f8f8;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 6px;
  margin-right: 6px;
}

.modal-event-details {
  padding-top: 0;
}

.modal-event-details-overflow {
  overflow-x: hidden;
  max-height: calc(100vh - 115px);
  padding: 0 5px 0 0;
}

.event-details-img {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
  height: 359px;
  margin-bottom: 20px;
}

.event-details-date {
  border-radius: 8px;
  background: #eef1f1;
  padding: 4px;
  color: #202827;
  font-weight: 800;
  font-size: 39px;
  text-align: center;
}
.event-details-date-underline {
  border-radius: 999px;
  background: #0cb0ab;
  width: 76px;
  height: 8px;
}
.event-details-time {
  color: #000;
  font-size: 20px;
  margin-bottom: 0px;
}
.event-details-title {
  color: #043e3c;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 0;
}

.event-details-tab {
  padding: 6px 15px;
  margin: 1px 12px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 999px;
  border: 1px solid #b8c6c5;
  background: #eef1f1;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.event-details-tab-active {
  padding: 6px 15px;
  margin: 1px 12px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 999px;
  border: 1px solid #076461;
  background: #d9fcfb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.event-details-view-reg {
  columns: #404f4e;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  justify-content: flex-end;
  align-items: center;
}

.event-discuss-tab {
  color: #b8c6c5;
  font-size: 16px;
  font-weight: 500;
}

.event-discuss-tab-active {
  color: #06514f;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid;
}

.event-discuss-form {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.event-discuss-draft {
  border-radius: 8px;
  border: 1px solid #fff;
  background: #dde4e3;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 16px;
}

.event-discuss-blog {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #f7f8f8;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 16px;
}

.event-discuss-blog-time {
  color: #809997;
  font-size: 13px;
  /* padding-bottom: 12px; */
}

.user-img {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin-bottom: 20px;
}

.user-img-edit {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.user-name {
  color: #101828;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0;
}
.user-name-edit {
  color: #344054;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.user-designation {
  color: #076461;
  text-align: center;
  font-size: 16px;
  margin-bottom: 0;
}
.user-designation-edit {
  color: #667085;
  font-family: Inter;
  font-size: 16px;
  margin-bottom: 0;
}
.user-url-edit {
  color: #667085;
  font-size: 13px;
  margin-bottom: 0;
}

.add {
  border-radius: 4px;
  border: 1px dashed #d0d5dd;
  background: #fff;
  padding: 8px;
  width: 40px;
  height: 40px;
}

.btn-arrow {
  border-radius: 4px;
  border: 1px dashed #d0d5dd;
  background: #fff;
  width: 40px;
}

.upload-user-profile {
  border-radius: 4px;
  border: 1px dashed #d0d5dd;
  background: #fff;
  width: 40px;
  height: 40px;
}

.university_profile_head {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
  height: 320px;
}

.event-publish {
  background-color: #fc7300;
  color: white;
  border-radius: 4px 0px;
  margin-left: -1px;
  margin-top: -1px;
  display: inline-block;
  padding: 4px 6px;
}

@media only screen and (max-width: 1200px) {
  .event-details-view-reg {
    margin-top: 20px;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 991px) {
  .mb20-16 {
    margin-bottom: 16px;
  }

  .event-content {
    padding: 112px 0 20px;
  }

  .event-card-overflow {
    height: calc(100vh - 180px);
  }

  .event-list {
    height: calc(100vh - 285px);
  }

  .event-details-img {
    height: 350px;
    margin-bottom: 16px;
  }

  .event-details-time {
    font-size: 11px;
  }

  .event-details-title {
    font-size: 16px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 767px) {
  .event-content {
    padding: 92px 0 0px;
  }

  .event-card-overflow {
    height: calc(100vh - 126px);
  }

  .event-card {
    background-color: white;
    padding: 16px;
    border-radius: 0;
  }

  .event-details-img {
    height: 167px;
    border-radius: 12px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
}
