.w-1px {
  width: 1px;
}

.w-2px {
  width: 2px;
}

.w-3px {
  width: 3px;
}

.w-4px {
  width: 4px;
}

.w-5px {
  width: 5px;
}

.w-6px {
  width: 6px;
}

.w-7px {
  width: 7px;
}

.w-8px {
  width: 8px;
}

.w-9px {
  width: 9px;
}

.w-10px {
  width: 10px;
}

.w-11px {
  width: 11px;
}

.w-12px {
  width: 12px;
}

.w-13px {
  width: 13px;
}

.w-14px {
  width: 14px;
}

.w-15px {
  width: 15px;
}

.w-16px {
  width: 16px;
}

.w-17px {
  width: 17px;
}

.w-18px {
  width: 18px;
}

.w-19px {
  width: 19px;
}

.w-20px {
  width: 20px !important;
}

.w-21px {
  width: 21px;
}

.w-22px {
  width: 22px;
}

.w-23px {
  width: 23px;
}

.w-24px {
  width: 24px;
}

.w-25px {
  width: 25px;
}

.w-26px {
  width: 26px;
}

.w-27px {
  width: 27px;
}

.w-28px {
  width: 28px;
}

.w-29px {
  width: 29px;
}

.w-30px {
  width: 30px;
}

.w-31px {
  width: 31px;
}

.w-32px {
  width: 32px;
}

.w-33px {
  width: 33px;
}

.w-34px {
  width: 34px;
}

.w-35px {
  width: 35px;
}

.w-36px {
  width: 36px;
}

.w-37px {
  width: 37px;
}

.w-38px {
  width: 38px;
}

.w-39px {
  width: 39px;
}

.w-40px {
  width: 40px;
}

.w-41px {
  width: 41px;
}

.w-42px {
  width: 42px;
}

.w-43px {
  width: 43px;
}

.w-44px {
  width: 44px;
}

.w-45px {
  width: 45px;
}

.w-46px {
  width: 46px;
}

.w-47px {
  width: 47px;
}

.w-48px {
  width: 48px;
}

.w-49px {
  width: 49px;
}

.w-50px {
  width: 50px;
}

.w-51px {
  width: 51px;
}

.w-52px {
  width: 52px;
}

.w-53px {
  width: 53px;
}

.w-54px {
  width: 54px;
}

.w-55px {
  width: 55px;
}

.w-56px {
  width: 56px;
}

.w-57px {
  width: 57px;
}

.w-58px {
  width: 58px;
}

.w-59px {
  width: 59px;
}

.w-60px {
  width: 60px;
}

.w-61px {
  width: 61px;
}

.w-62px {
  width: 62px;
}

.w-63px {
  width: 63px;
}

.w-64px {
  width: 64px;
}

.w-65px {
  width: 65px;
}

.w-66px {
  width: 66px;
}

.w-67px {
  width: 67px;
}

.w-68px {
  width: 68px;
}

.w-69px {
  width: 69px;
}

.w-70px {
  width: 70px;
}

.w-71px {
  width: 71px;
}

.w-72px {
  width: 72px;
}

.w-73px {
  width: 73px;
}

.w-74px {
  width: 74px;
}

.w-75px {
  width: 75px;
}

.w-76px {
  width: 76px;
}

.w-77px {
  width: 77px;
}

.w-78px {
  width: 78px;
}

.w-79px {
  width: 79px;
}

.w-80px {
  width: 80px;
}

.w-81px {
  width: 81px;
}

.w-82px {
  width: 82px;
}

.w-83px {
  width: 83px;
}

.w-84px {
  width: 84px;
}

.w-85px {
  width: 85px;
}

.w-86px {
  width: 86px;
}

.w-87px {
  width: 87px;
}

.w-88px {
  width: 88px;
}

.w-89px {
  width: 89px;
}

.w-90px {
  width: 90px;
}

.w-91px {
  width: 91px;
}

.w-92px {
  width: 92px;
}

.w-93px {
  width: 93px;
}

.w-94px {
  width: 94px;
}

.w-95px {
  width: 95px;
}

.w-96px {
  width: 96px;
}

.w-97px {
  width: 97px;
}

.w-98px {
  width: 98px;
}

.w-99px {
  width: 99px;
}

.w-100px {
  width: 100px;
}

.w-110px {
  width: 110px;
}
.w-120px {
  width: 120px;
}
.w-160px {
  width: 160px;
}
.w-290px {
  width: 290px;
}

.mw-70px {
  min-width: 70px;
}
.mw-120px {
  min-width: 120px;
}
.mw-150px {
  min-width: 150px;
}
.mw-290px {
  min-width: 290px;
}

.mh-250px {
  max-height: 250px;
  overflow-y: auto;
}

.mxw-600px {
  max-width: 600px;
}

.h-300px {
  height: 300px;
  overflow-y: auto;
}
