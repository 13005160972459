@import url(./theme.css);
@import url(./event.css);
@import url(./settings.css);
@import url(./lead.css);
@import url(./web.css);

html {
  background-color: white;
}
/* body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ae9d8966;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2154ac;
}

.dropdown-toggle::after {
  display: none;
}

.minH-100px {
  min-height: 100px;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

#login {
  z-index: 5;
  overflow-x: hidden;
  width: 300px;
}

.login-btn {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #fff;
  color: #076461;
  border: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
}

.btn-theme-primary {
  border-radius: 8px;
  border: 1px solid #076461;
  background: #076461;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: white;
  font-size: 14px;
  padding: 8px 14px;
  text-align: center;
}

.btn-theme-secondary {
  border-radius: 8px;
  border: 1px solid #607d8b;
  background: #607d8b;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: white;
  font-size: 14px;
  padding: 8px 14px;
  text-align: center;
}

.btn-theme-light {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #344054;
  font-size: 14px;
  padding: 8px 14px;
  text-align: center;
}

.btn-theme-cancel {
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--gray-700, #344054);
  width: 100%;
  font-size: 14px;
  padding: 10px 18px;
  text-align: center;
}

.btn-theme-save-min {
  border-radius: 8px;
  border: 1px solid var(--teal-500, #076461);
  background: var(--colours-teal-500, #076461);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: white;
  font-size: 14px;
  padding: 8px 14px;
  text-align: center;
}

.btn-theme-cancel-min {
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--gray-700, #344054);
  font-size: 14px;
  padding: 8px 14px;
  text-align: center;
}

.card {
  padding: 30px;
  margin-bottom: 24px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}
.card-min {
  padding: 20px;
  margin-bottom: 24px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}
.card-filter {
  padding: 20px 30px;
  margin-bottom: 24px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}

.card-border {
  display: block;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}

.h-full {
  height: calc(100% - 24px);
}

.border {
  border: 1px solid #d0d5dd !important;
}

.h-140px {
  height: 140px;
}

.bg-cover-img {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
}

.tox-statusbar__text-container {
  display: none !important;
}

.tox-notification.tox-notification--in.tox-notification--warning {
  display: none !important;
}

.tox-notifications-container {
  visibility: hidden !important;
}

.custom-border {
  border-radius: 8px;
  border: 1px solid #c9d4d3;
}

.theme-accordion-header {
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #fff;
  color: #404f4e;
  padding: 8px 16px;
  margin-bottom: 8px;
  cursor: pointer;
}

.theme-accordion-header-open {
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px 4px 0 0;
  background-color: #fc7300;
  color: white;
  padding: 8px 16px;
  cursor: pointer;
}
