.setting {
  display: grid;
  grid-template-columns: 300px auto;
}
.setting-card-overflow {
  overflow-y: auto !important;
  height: calc(100vh - 155px);
  position: relative;
}

.setting-outlet-padding {
  padding: 30px 60px 30px 60px;
}

.setting-add {
  color: #fff;
  background: #076461;
  padding: 4px;
  margin-right: 4px;
}

.form-dynamic-elements {
  overflow-y: auto !important;
  height: calc(100vh - 260px);
  position: relative;
  border-radius: 8px;
  border: 1px solid #c9d4d3;
}

.form-elements-overflow {
  overflow-y: auto !important;
  height: calc(100vh - 370px);
  position: relative;
  border-radius: 8px;
  border: 1px solid #c9d4d3;
}

.form-elements {
  display: flex;
  font-size: 16px;
  margin-top: 12px;
  padding: 12px 16px;
  justify-content: space-between;
  border-radius: 5px;
  background: #f7f8f8;
}

.form-elements:hover {
  display: flex;
  font-size: 16px;
  margin-top: 12px;
  padding: 12px 16px;
  justify-content: space-between;
  border-radius: 5px;
  background: #dde4e3;
}

.form-elements-action {
  display: none;
}

.form-elements:hover .form-elements-action {
  display: block;
}

.form-elements-add {
  display: flex;
  font-size: 16px;
  margin-top: 12px;
  padding: 12px 16px;
  border-radius: 5px;
  background: #eef1f1;
  cursor: pointer;
}

.form-elements-select {
  display: flex;
  font-size: 16px;
  margin-top: 12px;
  padding: 12px 16px;
  border-radius: 5px;
  border: 2px solid #076461;
  color: #076461;
  background: #f7f8f8;
  cursor: pointer;
}

/* .form-build:hover {
  border-right: 3px solid #0cb0ab;
} */

@media only screen and (max-width: 991px) {
  .setting {
    display: grid;
    grid-template-columns: 0 auto;
  }
  .setting-card-overflow {
    height: calc(100vh - 135px);
  }
}

@media only screen and (max-width: 767px) {
  .setting-card-overflow {
    height: calc(100vh - 92px);
  }
}
@media only screen and (max-width: 575px) {
  .setting-outlet-padding {
    padding: 30px 30px 30px 30px;
  }
}
