.website {
  display: grid;
  grid-template-columns: 300px auto;
}

.web-card-overflow {
  overflow-y: auto !important;
  height: calc(100vh - 155px);
  position: relative;
}

.web-outlet-padding {
  padding: 30px 60px 30px 60px;
}

@media only screen and (max-width: 991px) {
  .website {
    display: grid;
    grid-template-columns: 0 auto;
  }

  .web-card-overflow {
    height: calc(100vh - 135px);
  }
}

@media only screen and (max-width: 767px) {
  .web-card-overflow {
    height: calc(100vh - 92px);
  }
}

@media only screen and (max-width: 575px) {
  .web-outlet-padding {
    padding: 30px 30px 30px 30px;
  }
}

.linkedIn {
  color: #1b6ec2;
  text-decoration: none;
  padding: 5px 10px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #1b6ec2;
  background: transparent;
}

.linkedIn:hover {
  color: white;
  background: #1b6ec2;
}
