.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}

.fs-1px {
  font-size: 1px;
}

.fs-2px {
  font-size: 2px;
}

.fs-3px {
  font-size: 3px;
}

.fs-4px {
  font-size: 4px;
}

.fs-5px {
  font-size: 5px;
}

.fs-6px {
  font-size: 6px;
}

.fs-7px {
  font-size: 7px;
}

.fs-8px {
  font-size: 8px;
}

.fs-9px {
  font-size: 9px;
}

.fs-10px {
  font-size: 10px;
}

.fs-11px {
  font-size: 11px;
}

.fs-12px {
  font-size: 12px;
}

.fs-13px {
  font-size: 13px;
}

.fs-14px {
  font-size: 14px;
}

.fs-15px {
  font-size: 15px;
}

.fs-16px {
  font-size: 16px;
}

.fs-17px {
  font-size: 17px;
}

.fs-18px {
  font-size: 18px;
}

.fs-19px {
  font-size: 19px;
}

.fs-20px {
  font-size: 20px;
}

.fs-21px {
  font-size: 21px;
}

.fs-22px {
  font-size: 22px;
}

.fs-23px {
  font-size: 23px;
}

.fs-24px {
  font-size: 24px;
}

.fs-25px {
  font-size: 25px;
}

.fs-26px {
  font-size: 26px;
}

.fs-27px {
  font-size: 27px;
}

.fs-28px {
  font-size: 28px;
}

.fs-29px {
  font-size: 29px;
}

.fs-30px {
  font-size: 30px;
}

.fs-31px {
  font-size: 31px;
}

.fs-32px {
  font-size: 32px;
}

.fs-33px {
  font-size: 33px;
}

.fs-34px {
  font-size: 34px;
}

.fs-35px {
  font-size: 35px;
}

.fs-36px {
  font-size: 36px;
}

.fs-37px {
  font-size: 37px;
}

.fs-38px {
  font-size: 38px;
}

.fs-39px {
  font-size: 39px;
}

.fs-40px {
  font-size: 40px;
}

.fs-41px {
  font-size: 41px;
}

.fs-42px {
  font-size: 42px;
}

.fs-43px {
  font-size: 43px;
}

.fs-44px {
  font-size: 44px;
}

.fs-45px {
  font-size: 45px;
}

.fs-46px {
  font-size: 46px;
}

.fs-47px {
  font-size: 47px;
}

.fs-48px {
  font-size: 48px;
}

.fs-49px {
  font-size: 49px;
}

.fs-50px {
  font-size: 50px;
}

.fs-51px {
  font-size: 51px;
}

.fs-52px {
  font-size: 52px;
}

.fs-53px {
  font-size: 53px;
}

.fs-54px {
  font-size: 54px;
}

.fs-55px {
  font-size: 55px;
}

.fs-56px {
  font-size: 56px;
}

.fs-57px {
  font-size: 57px;
}

.fs-58px {
  font-size: 58px;
}

.fs-59px {
  font-size: 59px;
}

.fs-60px {
  font-size: 60px;
}

.fs-61px {
  font-size: 61px;
}

.fs-62px {
  font-size: 62px;
}

.fs-63px {
  font-size: 63px;
}

.fs-64px {
  font-size: 64px;
}

.fs-65px {
  font-size: 65px;
}

.fs-66px {
  font-size: 66px;
}

.fs-67px {
  font-size: 67px;
}

.fs-68px {
  font-size: 68px;
}

.fs-69px {
  font-size: 69px;
}

.fs-70px {
  font-size: 70px;
}

.fs-71px {
  font-size: 71px;
}

.fs-72px {
  font-size: 72px;
}

.fs-73px {
  font-size: 73px;
}

.fs-74px {
  font-size: 74px;
}

.fs-75px {
  font-size: 75px;
}

.fs-76px {
  font-size: 76px;
}

.fs-77px {
  font-size: 77px;
}

.fs-78px {
  font-size: 78px;
}

.fs-79px {
  font-size: 79px;
}

.fs-80px {
  font-size: 80px;
}

.fs-81px {
  font-size: 81px;
}

.fs-82px {
  font-size: 82px;
}

.fs-83px {
  font-size: 83px;
}

.fs-84px {
  font-size: 84px;
}

.fs-85px {
  font-size: 85px;
}

.fs-86px {
  font-size: 86px;
}

.fs-87px {
  font-size: 87px;
}

.fs-88px {
  font-size: 88px;
}

.fs-89px {
  font-size: 89px;
}

.fs-90px {
  font-size: 90px;
}

.fs-91px {
  font-size: 91px;
}

.fs-92px {
  font-size: 92px;
}

.fs-93px {
  font-size: 93px;
}

.fs-94px {
  font-size: 94px;
}

.fs-95px {
  font-size: 95px;
}

.fs-96px {
  font-size: 96px;
}

.fs-97px {
  font-size: 97px;
}

.fs-98px {
  font-size: 98px;
}

.fs-99px {
  font-size: 99px;
}

.fs-100px {
  font-size: 100px;
}
