.leads {
  display: grid;
  grid-template-columns: 300px auto;
}

.lead-card-overflow {
  overflow-y: auto !important;
  height: calc(100vh - 155px);
  position: relative;
}

.lead-outlet-padding {
  /* padding: 30px 60px 30px 60px; */
  padding: 48px 60px;
}

.dashboard-card {
  position: relative;
  padding: 24px 30px;
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #fff;
  height: calc(100% - 20px);
}

.lead-chart-btn {
  padding: 16px 0;
}

.lead-chart-btn-active {
  border-bottom: 2px solid #0cb0ab;
  padding: 16px 0;
  color: #0cb0ab;
}

.lead-numbering {
  padding: 0px 6px;
  border-radius: 100px;
  background: #5d7472;
  color: white;
  font-size: 16px;
}

.profile-timeline li {
  position: relative;
  list-style: none;
  padding-left: 28px;
  padding-bottom: 16px;
  margin-left: -20px;
}

.profile-timeline li::before {
  content: url(/src/assets/img/timelinIcon.svg);
  position: relative;
  left: -40px;
}

/* .profile-timeline li .content {
  margin-top: -120px;
} */

.lead-availability {
  border-radius: 4px;
  border: 1px solid #a8a8a8;
  background: #fff1e6;
  color: #252525;
  padding: 12px;
  font-weight: 500;
}

@media only screen and (max-width: 991px) {
  .leads {
    display: grid;
    grid-template-columns: 0 auto;
  }

  .lead-card-overflow {
    height: calc(100vh - 135px);
  }
}

@media only screen and (max-width: 767px) {
  .lead-card-overflow {
    height: calc(100vh - 92px);
  }
}

@media only screen and (max-width: 575px) {
  .lead-outlet-padding {
    padding: 30px 30px 30px 30px;
  }
}

.lead-connect {
  border-radius: 8px;
  background-color: #fff;
  color: #667085;
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  padding: 8px;
}

.lead-setting-icon {
  width: 25px;
}

/* .sa .apexcharts-bar-area:hover {
  fill: rgb(4 93 94);
} */
