body {
  margin: 0;
  background-color: var(--bgColor);
  color: var(--textColor);
  overflow-x: hidden;
  font-size: 14px;
}

.overflowX-auto {
  overflow-x: auto;
}

.overflowY-hidden {
  overflow-y: hidden;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar {
  background-color: var(--bgColor);
}

.mb20-16 {
  margin-bottom: 20px;
}

.content {
  margin: 0;
  padding: 122px 2% 32px;
}

.dashboard-layout-sidebar {
  width: 300px !important;
}

.text-gray {
  color: #404f4e;
}

.content {
  margin: 0;
  padding: 122px 2% 32px;
}

.navbar {
  background-color: var(--navColor);
  color: var(--textColor);
  height: 90px;
  padding: 0px 3%;
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navmenu {
  display: block;
  padding: 10px 18px;
  color: var(--textColor);
  text-decoration: none;
  margin: 0 12px;
}

.navmenu-active {
  background-color: #d9fcfb;
  color: #404f4e;
  border-radius: 8px;
}

.togglemenu {
  display: block;
  color: var(--textColor);
  text-decoration: none;
}

.accordion-button:not(.collapsed) {
  color: rgb(39, 39, 83);
  background-color: transparent;
  box-shadow: none;
  padding: 12px 30px;
}

.accordion-button.collapsed {
  background-color: transparent;
  padding: 12px 30px;
}

.accordion-item {
  background-color: transparent !important;
}

.sidemenu {
  display: flex;
  padding: 12px 30px;
  color: var(--textColor);
  text-decoration: none;
  margin: 4px 0;
}

.sidemenu-active {
  background-color: #fc7300;
  color: white;
  border-radius: 8px;
}

.modal-body {
  padding: 24px;
}

.modal-content {
  border: 0;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

.modal-overflow {
  overflow-x: hidden;
  max-height: 50vh;
  padding: 0 5px 0 0;
}

.hr-heading {
  position: absolute;
  top: -12px;
  color: #344054;
  font-weight: 500;
  background-color: white;
  padding: 0 8px;
  left: 50%;
  transform: translateX(-50%);
}

.table {
  margin: 0;
}

th {
  background-color: #eef1f1 !important;
  border: 0;
}

tr {
  font-size: 12px;
  padding: 12px 8px !important;
  min-height: 40px !important;
}

tr:hover td {
  background: #f7f8f8;
}

.back-btn {
  display: inline-block;
  color: #5d7472;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 16px 12px 0;
  cursor: pointer;
}

.pagination-overflow {
  overflow-x: auto !important;
  position: relative;
  top: 30px;
}

a.page-link {
  display: block;
  border: 0;
  color: #202827;
}

span.page-link {
  display: block;
  background-color: #076461 !important;
  border: 0;
  border-radius: 100px;
}

.tag-btn {
  border-radius: 999px;
  padding: 8px 12px;
  display: inline-block;
  margin: 0 6px 6px;
  font-weight: 600;
}

.filter-btn {
  font-size: 14px;
  margin: 17px 12px;
}

.filter-btn-active {
  color: #0cb0ab;
  font-size: 14px;
  margin: 17px 12px;
}

.click-over {
  position: absolute;
  background: white;
  right: 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 2px;
  z-index: 1;
}

.form-select:focus,
.form-control:focus {
  box-shadow: none;
}

.form-check-input {
  width: 36px !important;
  height: 20px !important;
  /* background-color: #dde4e3; */
  background-color: white;
  border: 1px solid #a89d9d;
}

.form-check-input:checked {
  width: 36px !important;
  height: 20px !important;
  background-color: #076461;
}

.apexcharts-toolbar {
  display: none !important;
}

.download-btn {
  border: 1px solid #076461;
  border-radius: 5px;
  padding: 7px 15px;
  color: #076461;
  font-size: 15px;
  font-weight: 600;
  background: #ffffff;
}

.icon-btn {
  color: #fff;
  background: #076461;
  /* padding: 2px; */
}

.copy-text {
  background-color: #eef1f1;
  border-radius: 25px;
  padding: 5px 10px;
  color: #076461;
}

@media only screen and (max-width: 991px) {
  .mb20-16 {
    margin-bottom: 16px;
  }

  .content {
    padding: 112px 2% 20px;
  }
}

@media only screen and (max-width: 767px) {
  .content {
    padding: 92px 0 0px;
  }
}

@media only screen and (max-width: 575px) {
  .modal-dialog {
    position: absolute;
    bottom: 0;
    margin: 0;
    width: 100%;
    transform: none !important;
  }

  .modal-content {
    position: absolute;
    bottom: 0;
    border-radius: 12px 12px 0px 0px;
  }

  .modal-body {
    padding: 16px;
  }
}

.ok {
  border: 1px solid #d0d5dd;
  padding: 15px;
  border-radius: 8px;
}
